<template>
  <b-card-code
    :title="tableTitle"
    class="vue-good-datatable-view btn-mobile-adjust"
  >
    <div
      class="d-flex justify-content-between"
      dir="ltr"
      :class="{ 'mb-1': !showSearch }"
    >
      <div
        class="custom-search d-flex justify-content-start align-items-center grid-main-action-button"
      >
        <img
          id="export_excel"
          style="height: 25px"
          class="cursor-pointer mr-1"
          :src="require('@/assets/images/manmudra/excel.png')"
          @click="exportExcel('excel')"
        >
        <app-tooltip
          target="export_excel"
          placement="top"
          :title="'Export as Excel'"
        />
        <img
          id="export_csv"
          style="height: 25px"
          class="cursor-pointer mr-1"
          :src="require('@/assets/images/manmudra/csv.png')"
          @click="exportExcel('csv')"
        >
        <app-tooltip
          target="export_csv"
          placement="top"
          :title="'Export as CSV'"
        />
        <img
          id="export_pdf"
          style="height: 25px"
          class="cursor-pointer mr-1"
          :src="require('@/assets/images/manmudra/pdf.png')"
          @click="exportExcel('pdf')"
        >
        <app-tooltip
          target="export_pdf"
          placement="top"
          :title="'Export as PDF'"
        />
        <img
          v-if="showUpload"
          id="show_upload"
          style="height: 25px"
          class="cursor-pointer mr-1"
          :src="require('@/assets/images/app-icons/update.png')"
          @click="$emit('upload')"
        >
        <app-tooltip
          v-if="showUpload"
          target="show_upload"
          placement="top"
          :title="'Upload Members'"
        />
      </div>
      <div class="custom-search d-flex justify-content-end grid-main-action-button">
        <b-form-group
          v-if="showSearch"
          class="switch-rtl-info"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="beginSearchList"
            />
          </div>
        </b-form-group>
        <b-button
          v-if="buttonLabel"
          variant="primary"
          style="height: 38px; margin-left: 20px"
          @click="$emit('add-new-info')"
        >
          {{ buttonLabel }}
        </b-button>
      </div>
    </div>
    <vue-good-table
      :mode="mode"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :total-rows="totalRecords"
      :is-loading.sync="isLoading"
      :pagination-options="{
        enabled: true,
        perPageDropdown: [10, 25, 50, 100],
      }"
      :rows="rows"
      :columns="columns"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template slot="emptystate">
        <p class="text-center mb-0">
          No data for table
        </p>
      </template>
      <template
        slot="table-column"
        slot-scope="props"
      >
        {{ props.column.label }}
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <div
          class="row-inner-tables"
          :class="{ 'text-center': props.column.align === 'center' }"
        >
          <span class="header-name-columns">{{ props.column.label }}</span>
          <span v-if="props.column.field === 'action'">
            <span
              v-if="
                props.column.type === 'dropdown' &&
                  props.column.buttons &&
                  props.column.buttons.length > 0
              "
            >
              <span
                v-for="(item, index) of props.column.buttons"
                :key="index"
              >
                <template v-if="props.column.isButtons">
                  <b-button
                    :variant="
                      item.renderVariant ? item.renderVariant(props.row) : item.variant
                    "
                    style="margin-right: 5px"
                    @click="item.action(props.row)"
                  >{{
                    item.renderButtonText ? item.renderButtonText(props.row) : item.text
                  }}</b-button>
                </template>
                <img
                  v-if="item.image && item.canRender && item.canRender(props.row)"
                  :id="`action-${index}-${props.row.originalIndex}`"
                  class="cursor-pointer action-icon-grid conditional-icon"
                  :src="item.image"
                  :title="item.title"
                  @click="item.action(props.row)"
                >
                <img
                  v-if="item.image && !item.canRender"
                  :id="`action-${index}-${props.row.originalIndex}`"
                  class="cursor-pointer action-icon-grid non-conditional-icon"
                  :src="item.image"
                  :title="item.title"
                  @click="item.action(props.row)"
                >
                <app-tooltip
                  v-if="item.image"
                  :target="`action-${index}-${props.row.originalIndex}`"
                  placement="top"
                  :title="item.title"
                />
              </span>
            </span>
            <span
              v-if="
                props.column.type === 'dropdown' &&
                  props.column.actions &&
                  props.column.actions.length > 0
              "
              class="d-flex"
            >
              <span
                v-for="(item, index) of props.column.actions"
                :id="`action-${index}-${props.row.originalIndex}`"
                :key="index"
                class="cursor-pointer"
                @click="item.action(props.row)"
              >
                <app-tooltip
                  :target="`action-${index}-${props.row.originalIndex}`"
                  placement="top"
                  :title="item.text"
                />
                <img
                  v-if="item.isIcon"
                  :id="`action-${index}-${props.row.originalIndex}`"
                  class="cursor-pointer action-icon-grid"
                  :src="item.icon ? item.icon : item.iconHtml"
                >
                <feather-icon
                  v-if="!item.isIcon"
                  :icon="item.icon ? item.icon : item.iconHtml"
                  size="20"
                  class="mr-50"
                />
              </span>
            </span>
          </span>
          <span v-else-if="props.column.useRenderer && !props.column.useResolver">
            {{ props.column.renderer(props.row) }}
          </span>
          <span v-else-if="props.column.field === 'dataCounter'">
            {{ props.row.originalIndex + 1 }}
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span
            v-else-if="props.column.useHtml"
            v-html="props.column.renderer(props.row)"
          />
          <!--eslint-enable-->
          <span v-else-if="props.column.showToggle">
            <b-form-checkbox
              :checked="props.formattedRow[props.column.field] ? true : false"
              name="check-button"
              switch
              inline
              @change="props.column.action(props.row)"
            />
          </span>
          <span v-else-if="props.column.useResolver">
            {{
              props.column.textRenderer
                ? props.column.textRenderer(props.row)
                : props.column.renderer(props.row)
            }}
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormGroup, BFormInput, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import {
  hideLoader,
  showDangerNotification,
  showLoader,
} from '@/@core/comp-functions/ui/app'
import { goToPath } from '@/auth/utils'
import AppTooltip from '@/@core/components/tooltip/AppTooltip.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    // BDropdown,
    // BDropdownItem,
    BButton,
    BFormCheckbox,
    AppTooltip,
  },
  props: {
    exportLocal: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    methodType: {
      type: String,
      default: '',
    },
    generateOutput: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default: () => [],
    },
    exportColumns: {
      type: Array,
      default: () => [],
    },
    showUpload: {
      type: Boolean,
      default: false,
    },
    loadLocalData: {
      type: Boolean,
      default: false,
    },
    excelColumns: {
      type: Array,
      default: () => [],
    },
    totalColumn: {
      type: String,
      default: 'total',
    },
    noSerial: {
      type: Boolean,
      default: false,
    },
    initializeOnLoad: {
      type: Boolean,
      default: true,
    },
    dataColumn: {
      type: String,
      default: 'data',
    },
    apiEndpoint: {
      type: String,
      default: '',
    },
    exportApiEndpoint: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'remote',
    },
    extraParams: {
      type: String,
      default: '',
    },
    defaultSortParams: {
      type: Object,
      default: () => {},
    },
    tableTitle: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
    exportMap: {
      type: String,
      default: 'res.data',
    },
    // eslint-disable-next-line vue/require-default-prop
    modifyData: {
      type: Function,
    },
  },
  data() {
    return {
      searchTerm: '',
      drawCounter: 1,
      isLoading: false,
      rows: [],
      originalRows: [],
      totalRecords: 0,
      serverParams: {
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10,
      },
    }
  },
  mounted() {
    if (this.initializeOnLoad) {
      this.loadItems()
    }
    window.thatList = this
  },
  methods: {
    beginSearchList: window.ManMudra.actions.debounce(() => {
      if (!window.thatList.searchTerm || window.thatList.searchTerm?.length >= 3) {
        window.thatList.loadItems()
      }
    }, 800),
    generateExport() {
      return new Promise((resolve, reject) => {
        try {
          let exportData = []
          if (this.loadLocalData) {
            exportData = this.mapExcelData(this.rows)
            resolve({
              fileName: `${new Date().valueOf()}.xlsx`,
              exportData,
            })
            return
          }
          const exportUrl = `${jwtDefaultConfig.groupsApi}${
            this.exportApiEndpoint ? this.exportApiEndpoint : this.apiEndpoint
          }`
          let splitUrl = exportUrl.split('?')
          splitUrl.push('page=all')
          splitUrl.push('from=0')
          splitUrl.push('count=1000')
          if (this.extraParams) {
            const paramsMap = this.extraParams.split('?')
            splitUrl.push(paramsMap.filter(x => x).join(''))
          }
          if (this.extraParams && this.extraParams !== 'completed') {
            splitUrl.push(this.extraParams)
          }
          splitUrl.push(`draw=${this.drawCounter}`)
          if (this.serverParams.sort && this.serverParams.sort.length > 0) {
            splitUrl.push(
              `${encodeURIComponent('order[0][column]')}=${
                this.serverParams.sort[0].columnIndex
              }`,
            )
            splitUrl.push(
              `${encodeURIComponent('order[0][dir]')}=${this.serverParams.sort[0].type}`,
            )
          } else {
            splitUrl.push(`${encodeURIComponent('order[0][column]')}=0`)
            splitUrl.push(`${encodeURIComponent('order[0][dir]')}=asc`)
          }
          splitUrl.push(`${encodeURIComponent('search[regex]')}=false`)
          splitUrl.push(`${encodeURIComponent('search[value]')}=${this.searchTerm}`)
          splitUrl.push(`textToSearch=${this.searchTerm}`)
          if (this.serverParams.sort && this.serverParams.sort.length > 0) {
            splitUrl.push(`order_by=${this.serverParams.sort[0].field}`)
            splitUrl.push(`order_direction=${this.serverParams.sort[0].type}`)
          } else if (
            this.defaultSortParams
            && this.defaultSortParams.field
            && this.defaultSortParams.type
          ) {
            splitUrl.push(`order_by=${this.defaultSortParams.field}`)
            splitUrl.push(`order_direction=${this.defaultSortParams.type}`)
          }
          splitUrl.push(`_=${new Date().valueOf()}`)
          if (!this.isRemote()) splitUrl = []
          let payload = {}
          let queryString = `${this.isRemote() ? '?' : ''}${splitUrl.join('&')}`
          if (this.methodType === 'PUT') {
            const obj = {}
            splitUrl.forEach(item => {
              const split = item.split('=')
              const key = split[0]
              let value = split[1]
              if (value === 'true' || value === 'false') {
                // eslint-disable-next-line no-const-assign
                value = value === 'true'
              }
              // eslint-disable-next-line no-restricted-globals
              obj[key] = !isNaN(parseFloat(value)) ? parseFloat(value) : value
            })
            obj.textToSearch = obj.textToSearch ? `${obj.textToSearch}` : ''
            payload = obj
            queryString = ''
          }
          const apiType = this.methodType === 'PUT' ? axios.put : axios.get
          apiType(
            `${jwtDefaultConfig.groupsApi}${this.apiEndpoint}${queryString}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${window.ManMudra.getters.accessToken()}`,
              },
            },
          ).then(({ data }) => {
            // eslint-disable-next-line no-unused-vars
            let res = data
            if (this.modifyData) {
              // eslint-disable-next-line no-param-reassign
              res = this.modifyData(res)
            } else {
              // eslint-disable-next-line no-eval
              const dataRows = eval(this.exportMap)

              const fileName = `${new Date().valueOf()}.xlsx`
              exportData = this.mapExcelData(dataRows)
              resolve({
                fileName,
                exportData,
              })
            }
          })
        } catch (error) {
          reject(error)
        }
      })
    },
    mapExcelData(dataRows) {
      const exportData = []
      try {
        dataRows.forEach((row, index) => {
          let payload = {}
          if (!this.noSerial) {
            payload['Sr. No'] = index + 1
          }
          if (
            this.exportAs !== 'pdf'
            && this.exportColumns
            && Array.isArray(this.exportColumns)
            && this.exportColumns.length > 0
          ) {
            payload = {}
            this.exportColumns.forEach(props => {
              if (props.resolver) {
                payload[props.label] = props.resolver(row)
              } else {
                payload[props.label] = this.resolveObjectData(props.value, row)
              }
            })
          } else {
            this.columns.forEach(props => {
              if (props.label && props.label !== 'Action' && !props.preventExport) {
                if (props.excelHtml) {
                  payload[props.label] = props.excelHtml(row)
                } else if (props.useRenderer && !props.useResolver) {
                  payload[props.label] = props.renderer(row)
                } else if (props.useResolver) {
                  payload[props.label] = props.textRenderer
                    ? props.textRenderer(row)
                    : props.renderer(row)
                } else {
                  payload[props.label] = this.resolveObjectData(props.field, row)
                }
                // eslint-disable-next-line eqeqeq
                if (!payload[props.label] && payload[props.label] != 0) {
                  payload[props.label] = ''
                }
              }
            })
          }
          exportData.push(payload)
        })
      } catch (err) {
        showDangerNotification(this, err.toString())
      }
      return exportData
    },
    convertToCsv(fileName, rows) {
      let csv = ''
      const header = Object.keys(rows[0])
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < header.length; j++) {
        let val = !header[j] ? '' : `${header[j]}`.toString()
        val = val.replace(/\t/gi, ' ')
        if (j > 0) {
          csv += '\t'
        }
        csv += val
      }
      csv += '\n'
      rows.forEach((item, i) => {
        const row = Object.values(rows[i])
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < row.length; j++) {
          let val = !row[j] ? '' : `${row[j]}`.toString()
          val = val.replace(/\t/gi, ' ')
          if (j > 0) {
            csv += '\t'
          }
          csv += val
        }
        csv += '\n'
      })

      // for UTF-16
      let cCode = ''
      const bArr = []
      bArr.push(255, 254)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < csv.length; ++i) {
        cCode = csv.charCodeAt(i)
        // eslint-disable-next-line no-bitwise
        bArr.push(cCode & 0xff)
        // eslint-disable-next-line no-bitwise
        bArr.push((cCode / 256) >>> 0)
      }

      const blob = new Blob([new Uint8Array(bArr)], {
        type: 'text/csv;charset=UTF-16LE;',
      })
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName)
      } else {
        const link = document.createElement('a')
        if (link.download !== undefined) {
          const url = window.URL.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          window.URL.revokeObjectURL(url)
        }
      }
    },
    exportExcel(exportAs = '') {
      if (!exportAs) {
        // eslint-disable-next-line no-param-reassign
        exportAs = 'excel'
      }
      if (this.exportLocal && exportAs === 'excel') {
        this.$emit('export-excel', {
          textToSearch: this.searchTerm,
          entries: this.totalRecords,
        })
        return
      }
      this.exportAs = exportAs
      showLoader()
      this.generateExport()
        .then(res => {
          if (exportAs === 'excel') {
            const ws = window.XLSX.utils.json_to_sheet(res.exportData)
            const wb = window.XLSX.utils.book_new()
            window.XLSX.utils.book_append_sheet(wb, ws)
            if (this.generateOutput) {
              const xlsxBinary = window.XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary',
              })
              const base64Xlsx = btoa(xlsxBinary)
              this.$emit('output-file', base64Xlsx)
            } else {
              window.XLSX.writeFile(wb, res.fileName)
            }
          } else if (exportAs === 'csv') {
            this.convertToCsv(`${new Date().valueOf()}.csv`, res.exportData)
          } else if (exportAs === 'pdf') {
            if (res.exportData.length > 0) {
              const keys = Object.keys(res.exportData[0])
              const pdfDefination = {
                pageSize: 'A3',
                pageOrientation: 'landscape',
                content: [
                  {
                    style: 'tableExample',
                    table: {
                      widths: keys.map(() => '*'),
                      headerRows: 1,
                      body: [
                        keys.map(x => ({
                          text: x,
                          style: 'tableHeader',
                        })),
                      ],
                    },
                  },
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: 'black',
                  },
                },
                defaultStyle: {
                  font: 'Arial',
                },
              }
              res.exportData.forEach((item, index) => {
                const tempData = []
                keys.forEach(data => {
                  tempData.push(res.exportData[index][data])
                })
                pdfDefination.content[0].table.body.push(tempData)
              })
              window.pdfMake
                .createPdf(pdfDefination)
                .download(`${new Date().valueOf()}.pdf`)
            }
          }
          hideLoader()
        })
        .catch(() => {
          hideLoader()
          showDangerNotification(this, 'Failed to generate export')
        })
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      if (!this.isRemote()) return
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
      if (!this.isRemote()) return
      this.loadItems()
    },
    resolveObjectData(path, obj) {
      return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj)
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
            columnIndex: this.columns.map(x => x.field).indexOf(params[0].field),
          },
        ],
      })
      if (!this.isRemote()) return
      this.loadItems()
    },
    onColumnFilter(params) {
      this.updateParams(params)
      if (!this.isRemote()) return
      this.loadItems()
    },
    resetToOriginal() {
      this.rows = []
      this.$nextTick(() => {
        this.rows = window.ManMudra.actions.cloneDeep(this.originalRows)
      })
    },
    setLocalData(data) {
      if (this.loadLocalData) {
        this.originalRows = window.ManMudra.actions.cloneDeep(data)
        this.rows = window.ManMudra.actions.cloneDeep(data)
      }
    },
    isRemote() {
      if (this.mode === 'remote') return true
      return false
    },
    loadItems(addExtraParam = null, additionalApiEndpoint = null) {
      if (this.loadLocalData) {
        return
      }
      if (!this.apiEndpoint) {
        this.rows = []
        return
      }
      showLoader()
      this.drawCounter += 1
      let tableFilters = []
      if (this.extraParams && this.extraParams !== 'completed') {
        this.extraParams.split('&').forEach(item => tableFilters.push(item))
      }
      if (addExtraParam) {
        tableFilters = []
        if (addExtraParam !== 'completed') {
          tableFilters.push(addExtraParam)
        }
      }
      tableFilters.push(`draw=${this.drawCounter}`)
      if (this.serverParams.sort && this.serverParams.sort.length > 0) {
        tableFilters.push(
          `${encodeURIComponent('column')}=${this.serverParams.sort[0].columnIndex}`,
        )
        tableFilters.push(
          `${encodeURIComponent('direction')}=${this.serverParams.sort[0].type}`,
        )
      } else {
        tableFilters.push(`${encodeURIComponent('column')}=0`)
        tableFilters.push(`${encodeURIComponent('direction')}=asc`)
      }
      tableFilters.push(
        `from=${(this.serverParams.page - 1) * this.serverParams.perPage}`,
      )
      tableFilters.push(`count=${this.serverParams.perPage}`)
      tableFilters.push(`page=${this.serverParams.page}`)
      tableFilters.push(`textToSearch=${this.searchTerm}`)
      if (this.serverParams.sort && this.serverParams.sort.length > 0) {
        tableFilters.push(`order_by=${this.serverParams.sort[0].field}`)
        tableFilters.push(`order_direction=${this.serverParams.sort[0].type}`)
      } else if (
        this.defaultSortParams
        && this.defaultSortParams.field
        && this.defaultSortParams.type
      ) {
        tableFilters.push(`order_by=${this.defaultSortParams.field}`)
        tableFilters.push(`order_direction=${this.defaultSortParams.type}`)
      }
      tableFilters.push(`_=${new Date().valueOf()}`)
      if (!this.isRemote()) tableFilters = []
      let payload = {}
      let queryString = `${this.isRemote() ? '?' : ''}${tableFilters.join('&')}`
      if (this.methodType === 'PUT') {
        const obj = {}
        tableFilters.forEach(item => {
          const split = item.split('=')
          const key = split[0]
          let value = split[1]
          if (value === 'true' || value === 'false') {
            // eslint-disable-next-line no-const-assign
            value = value === 'true'
          }
          // eslint-disable-next-line no-restricted-globals
          obj[key] = !isNaN(parseFloat(value)) ? parseFloat(value) : value
        })
        obj.textToSearch = obj.textToSearch ? `${obj.textToSearch}` : ''
        payload = obj
        queryString = ''
      }
      const apiType = this.methodType === 'PUT' ? axios.put : axios.get
      apiType(
        `${jwtDefaultConfig.groupsApi}${
          additionalApiEndpoint || this.apiEndpoint
        }${queryString}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${window.ManMudra.getters.accessToken()}`,
          },
        },
      )
        .then(({ data }) => {
          let res = data
          hideLoader()
          if (res.statusCode === '401') {
            goToPath(this, 'login')
          } else if (res.succeeded) {
            if (this.modifyData) {
              // eslint-disable-next-line no-param-reassign
              res = this.modifyData(res)
            }
            // eslint-disable-next-line no-unused-vars
            const response = res
            this.$emit('table-response', response)
            // eslint-disable-next-line no-eval
            this.totalRecords = eval(this.totalColumn)
            // eslint-disable-next-line no-eval
            const dataRows = eval(this.dataColumn)
            if (this.columns.find(x => x.resolvePath)) {
              const resolveColumns = this.columns.filter(x => x.resolvePath)
              dataRows.forEach(element => {
                resolveColumns.forEach(x => {
                  // eslint-disable-next-line no-param-reassign
                  element[x.rField ? x.rField : x.field] = this.resolveObjectData(
                    x.resolvePath,
                    element,
                  )
                })
              })
            }
            if (this.columns.find(x => x.useResolver)) {
              const resolveColumns = this.columns.filter(x => x.useResolver)
              dataRows.forEach(element => {
                resolveColumns.forEach(x => {
                  if (x.textRenderer) {
                    // eslint-disable-next-line no-param-reassign
                    element[x.rField ? x.rField : x.field] = x.textRenderer(element)
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    element[x.rField ? x.rField : x.field] = x.renderer(element)
                  }
                })
              })
            }
            this.rows = dataRows
          } else if (res.error) {
            this.rows = []
            showDangerNotification(this, res.error)
          }
          this.originalRows = window.ManMudra.actions.cloneDeep(this.rows)
          this.$emit('table-rendered')
        })
        .catch(err => {
          if (err.message === 'Unexpected end of JSON input') {
            goToPath(this, 'login')
          } else {
            console.log(err)
            hideLoader()
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style scoped>
.action-icon-grid {
  width: 20px;
  cursor: pointer;
  margin: 0 10px 0 0;
}

@media only screen and (max-width: 767px) {
  .row-inner-tables.text-center {
    text-align: initial !important;
  }
}
</style>
