<template>
  <div class="app-event-list">
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="events"
          :button-label="$manMudra.getters.isSuperAdmin() ? 'Add Event' : ''"
          :columns="eventColumns"
          :show-search="false"
          :total-column="'response.entries'"
          :data-column="'response.data'"
          :api-endpoint="'/api/Event/ListAsync'"
          :extra-params="extraParams"
          :export-columns="exportColumns"
          :export-api-endpoint="'/api/Event/ExportListAsync'"
          @add-new-info="$helpers.goTo(that, 'group-event-add')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { appDeleteEvents } from '@/@core/services/groups'
import {
  showDangerNotification,
  showSuccessNotification,
} from '@/@core/comp-functions/ui/app'

export default {
  components: {
    GoodDataTable,
    BRow,
    BCol,
  },
  data() {
    return {
      that: this,
      eventColumns: [
        {
          label: 'Event Code',
          field: 'code',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Description',
          field: 'assistanceDesc',
        },
        {
          label: 'Start Date',
          field: 'startDate',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.$helpers.goTo(this, 'group-event-edit', {
                  eventId: props.id,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.deleteEvents(props.id)
              },
            },
          ].filter(x => (window.ManMudra.getters.isSuperAdmin() ? x : x.text !== 'Edit')),
        },
      ],
      exportColumns: [
        {
          label: 'Type',
          value: 'eventType',
        },
        {
          label: 'EventCode',
          value: 'code',
        },
        {
          label: 'StartDate',
          value: 'startDate',
        },
        {
          label: 'EndDate',
          value: 'endDate',
        },
        {
          label: 'Amount',
          value: 'amount',
        },
        {
          label: 'Details',
          value: 'assistanceDesc',
        },
        {
          label: 'Status',
          value: 'status',
        },
      ],
    }
  },

  computed: {
    unionSelected() {
      return this.$store.getters['groups/currentUnion']
    },
    extraParams() {
      if (!this.unionSelected) return ''
      return `unionId=${this.unionSelected.id}`
    },
  },
  watch: {
    unionSelected: {
      immediate: true,
      handler() {
        setTimeout(() => {
          this.runInitial()
        }, 150)
      },
    },
  },
  methods: {
    runInitial() {
      if (this.$refs.events) {
        this.$refs.events.loadItems()
      }
    },
    deleteEvents(eventId) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Do you really want to delete?')) {
        appDeleteEvents({ id: eventId }).then(({ data }) => {
          if (data.succeeded) {
            showSuccessNotification(this, data.message)
            this.$refs.events.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
        })
      }
    },
  },
}
</script>
